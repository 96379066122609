import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Immobilienrecht() {
  return (
    <LayoutXT>
      <h1 className="left">Grundstücksrecht &amp; Immobilienrecht</h1>
      <div className="rem-of-13 gray-a lheight18">
        Im Grundstücksrecht &amp; Immobilienrecht beraten wir überwiegend bei
        Immobilien-transaktionen, sowohl aus Veräußererperspektive als auch aus
        Sicht eines Erwerbers. <br />
        <br />
        Soweit bereits ein notarieller Vertragsentwurf zur Übertragung einer
        Immobilie vorliegt, übernehmen wir gerne die Durchsicht und Prüfung
        dieses Vertragsentwurfs. Hierbei ist unsere Zielsetzung, dass Ihre
        Interessen und Bedürfnisse in dem Vertragsentwurf bestmöglich gewahrt
        bleiben. <br />
        <br />
        Bei vorhandenem Grundbesitz beraten wir Sie fachkundig, wie im Zuge der
        Vermögensübergabe an die nachfolgende Generation die übergebende
        Generation über Nießbrauchsrechte, Wohnrechte oder mittels weiteren
        Gestaltungsmitteln abgesichert werden kann, so dass Ihr Vermögen bereits
        zu Lebzeiten transparent und geordnet auf die zukünftige Generation
        übertragen werden kann, andererseits aber Ihre Altersversorgung über
        Ihre Immobilie gewährleistet ist.
        <br />
        <br />
        Auch bei jeder weiteren Fragestellung zu Ihrem Grundstück bzw. zu Ihrer
        Immobilie sind wir für Sie beratend tätig. <br />
        <br />
        Gerne übernehmen wir auch die Korrespondenz mit dem Grundbuchamt, soweit
        rechtliche Fragen oder Streitpunkte abzuklären sind und werden
        stellvertretend für Sie die notwendige Verständigung herbeiführen.
      </div>
    </LayoutXT>
  )
}
